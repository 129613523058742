<script>
import HomeHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: "product1",
  components: {HomeFooter, HomeHeader},
  data() {
    return {
      pid: 1,
      productList: [
        {
          // pid=1

        }
      ]
    }
  },
  mounted() {
    this.pid = this.$route.query.pid;
  },
  methods: {
    /** 跳转-商品 */
    pathToProduct(url, id) {
      if(id === 1) {
        this.$router.push({
          path: "/product/product1"
        })
      } else if(id === 2) {
        this.$router.push({
          path: "/product/product2"
        })
      } else if(id === 3) {
        this.$router.push({
          path: "/product/product3"
        })
      } else if(id === 4) {
        this.$router.push({
          path: "/product/product4"
        })
      }
    }
  }
}
</script>

<template>
  <div class="page">
    <home-header></home-header>
    <div class="container">
      <div class="product clearfix">
        <div class="product_introduction clearfix">
          <div class="img">
            <img src="@/assets/images/pd-1-m.png" alt="乐肤洁控油清爽洁面乳">
          </div>
          <div class="content">
            <div class="title">豆乎氨基酸净澈洁面乳&nbsp;</div>
            <div class="title_e">AMINO ACID CLEANSER</div>
            <!--%if(!pr.getTag().equals("")){%> <div class="txt_0"><span>%=pr.getTag()%></span></div>%}%>-->
            <div class="content_txt">
              <div class="introduce">
                <div class="txt_0">干净舒适的氨基酸洁面乳</div>
                <div class="txt_1">
                  <dd>氨基酸表活结构天生与皮肤接近，带来优秀的清洁力同时，温和无刺激。
                    <dd>挑选国际品牌热推的两大氨基酸表活，不添加任何皂基成分，易冲水，洗后肌肤清透净白。
                      <dd>保护天然保湿因子，不过度脱脂，控制水分蒸发，有效改善清洁后紧绷感。 </dd>
                </div>
                <div class="txt_2 clearfix"><b>使用方法：&nbsp;</b><span>用清水将全脸湿润，取花生米大小(约1g) 于掌心加适量水搓揉出泡沫，用泡沫轻柔按摩全脸，可重点揉搓前额、鼻子和下巴位置，约1分钟后使用清水将脸洗净即可。</span>
                </div>
                <div class="txt_3 clearfix">
                  <div style="HEIGHT: 50px; CLEAR: both"><span>￥ 59.9</span><span class="s">/98g
										</span><a style="cursor: pointer" href="https://skycos.taobao.com/"
                              target="_blank">立即购买</a></div>
                </div>
              </div>
            </div>
          </div>
          <h3
              style=" width:138px; text-align:center; float:right; font-size: 14px; padding:15px 0; color: #21bdbd; font-weight: bold;">
            搭配使用更有效</h3>
          <ul class="related_products">
            <li>
              <a><img src="@/assets/images/pd-2-a.png"
                                                     alt="乐肤洁抗痘护理爽肤液"></a>
              <br>
              豆乎萃活康肤水
              <span class="li_a"><a @click="pathToProduct('none', 2)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-3-a.png"
                                                     alt="乐肤洁清爽控油乳液"></a>
              <br>
              豆乎植合舒安乳
              <span class="li_a"><a @click="pathToProduct('none', 3)" style="cursor: pointer">查看</a></span>
            </li>
            <li>
              <a><img src="@/assets/images/pd-4-a.png"
                                                      alt="乐肤洁隐形净痘贴"></a>
              <br>
              豆乎植合维肤霜
              <span class="li_a"><a @click="pathToProduct('none', 4)" style="cursor: pointer">查看</a></span>
            </li>
          </ul>
        </div>
        <div class="container-image-box">
          <img class="container-image" src="@/assets/images/pd-1-d.jpg" alt>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/static/css/base.css";
@import "@/assets/static/css/themes.css";

.container-image-box {
  width: 100%;
  display: flex;
  justify-content: center;

  .container-image {
    width: 650px;
    margin: 50px auto;
  }
}
</style>
